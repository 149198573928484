/* Accordion Header Styles */
.AccordionHeader {
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  transition: background-color 0.3s ease;
}

.AccordionHeader:hover {
  background-color: #edf2f7;
}

/* Accordion Body Styles */
.AccordionBody {
  border: 1px solid #e2e8f0;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 1rem;
}

/* List Styles */
.AccordionBody ul {
  list-style-type: disc;
  margin-left: 1rem;
}

.AccordionBody ul li {
  margin-bottom: 0.5rem;
}

/* Specificity adjustment for list items */
.AccordionBody .list-item {
  margin-bottom: 0.5rem;
}
