.header {
  width: 100%;
  background: rgb(198, 6, 126);
  background: linear-gradient(
    180deg,
    rgba(198, 6, 126, 1) 0%,
    rgba(198, 6, 126, 1) 50%,
    rgba(147, 14, 190, 1) 100%
  );
  text-align: center;
  padding-top: 10%;
  color: white;
  /* height: 82vh; */
}

.header h1 {
  font-weight: bold;
  font-size: 28px;
padding-bottom: 20px;
}

.banner-mobile1 {
  width: 700px;
  height: 300px;
}

.apple {
  width: 200px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.google {
  width: 230px;
  height: auto;
  border-radius: 10px;
  margin-left: 40px;
  margin-bottom: 20px;
}

.section1 {
  width: 100%;
  background: rgb(198, 131, 6);
  background: linear-gradient(
    180deg,
    rgba(198, 131, 6, 1) 0%,
    rgba(196, 112, 223, 1) 100%
  );
  padding-top: 20px;
  padding-bottom: 20px;
}

.section1 h3 {
  text-align: center;
  font-weight: 900;
  
}

.section1 p {
  text-align: center;
  color: white;
}

.section2 {
  width: 100%;
  background: rgb(198, 6, 126);
  background: linear-gradient(
    180deg,
    rgba(198, 6, 126, 1) 0%,
    rgba(198, 6, 126, 1) 50%,
    rgba(147, 14, 190, 1) 100%
  );
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
}

.section2 .cover {
  display: flex;
  margin-top: 40px;
}

.section2 h3 {
  font-weight: 900;
  color: white;
  text-align: center;
  margin-top: 20px;
}

.part2 img {
  width: 300px;
  height: auto;
}

.p1 {
  display: flex;
  margin-left: 150px;
}

.p1 p {
  width: 400px;
  margin-top: 50px;
  font-size: 15px;
}

.p1 .hr {
  background-color: white;
  width: 40%;
  height: 2px;
  margin-top: 100px;
}

.p2 {
  display: flex;
  margin-right: 20%;
}

.p2 p {
  width: 500px;
  margin-top: 30px;
  font-size: 15px;
  margin-left: 5%;
}

.p2 .hr {
  background-color: white;
  width: 40%;
  height: 2px;
  margin-top: 100px;
}

.section3 {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background: rgb(198, 131, 6);
  background: linear-gradient(
    180deg,
    rgba(198, 131, 6, 1) 0%,
    rgba(196, 112, 223, 1) 100%
  );
}

.card {
  background-color: #80050500;
  display: flex;
  flex-direction: row;
  border: none;
  margin-top: 50px;
}

.section4 {
  width: 100%;
  background: rgb(198, 6, 126);
  background: linear-gradient(
    180deg,
    rgba(198, 6, 126, 1) 0%,
    rgba(198, 6, 126, 1) 50%,
    rgba(147, 14, 190, 1) 100%
  );
  padding-top: 20px;
  padding-bottom: 20px;
}

.section4 h3 {
  font-weight: 900;
  text-align: center;
  margin-top: 20px;
}

.footer {
  background: rgb(181, 7, 116);
  background: linear-gradient(
    317deg,
    rgba(181, 7, 116, 1) 0%,
    rgba(163, 172, 7, 1) 100%
  );
  color: #fff;
  bottom: 0;
  width: 100%;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}

.vr {
  width: 1px;
  height: 20px;
  background-color: #800505;
  margin-left: 10px;
  margin-right: 10px;
}

.footer a {
  font-size: 18px;
  text-decoration: none;
  color: white;
}

.footer p {
  font-size: 13px;
  width: 200px;
}

.footer-links {
  display: flex;
  margin-left: 50px;
  margin-top: 10px;
}

.footer .containerp {
  margin-left: 50%;
  font-size: 18px;
}

.containerp p {
  font-size: 14px;
}

@media (max-width: 768px) {
  .header {
    padding-top: 20%;
  }

  .header h2,
  .header p {
    font-size: 12px;
  }

  .banner-mobile1 {
    width: 250px;
    height: 100px;
  }

  .download {
    display: flex;
    margin-top: 20px;
  }

  .apple {
    width: 100px;
    height: auto;
    margin-left: 40px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .google {
    width: 110px;
    height: auto;
    border-radius: 10px;
    margin-left: 40px;
    margin-bottom: 20px;
  }

  .section1 h3 {
    font-size: 18px;
  }

  .section1 p {
    font-size: 14px;
  }

  .section2 {
    padding-top: 10px;
  }

  .section2 .cover {
    margin-top: 20px;
  }

  .section2 h3 {
    font-size: 18px;
    margin-top: 10px;
  }

  .part2 img {
    width: 150px;
    height: 400px;
  }

  .p1 {
    margin-left: 10%;
  }

  .p1 p {
    width: 100px;
    margin-top: 25px;
    font-size: 10px;
  }

  .p1 .hr {
    width: 20%;
    height: 2px;
    margin-top: 50px;
  }

  .p2 {
    display: flex;
    margin-right: 10%;
  }

  .p2 p {
    width: 100px;
    margin-top: 15px;
    font-size: 10px;
    margin-left: 2%;
  }

  .p2 .hr {
    background-color: white;
    width: 20%;
    height: 2px;
    margin-top: 50px;
  }

  .section4 {
    padding-top: 10px;
  }

  .section4 h3 {
    font-size: 18px;
    margin-top: 10px;
  }

  .footer {
    padding-top: 10px;
    display: grid;
  }

  .vr {
    width: 1px;
    height: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .footer a {
    font-size: 10px;
    width: 50px;
  }

  .footer p {
    font-size: 10px;
    text-align: center;
    width: 200px;
  }
}

.card1,
.card2,
.card3 {
  text-align: center;
  width: 90%;
  padding: 20px 5%;
}

.card1 img,
.card2 img,
.card3 img {
  width: 100px;
  height: 100px;
  border-radius: 60px;
  margin-left: 30%;
}

.card1 h3,
.card2 h3,
.card3 h3 {
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
}

.card1 p,
.card2 p,
.card3 p {
  text-align: left;
  color: white;
}

.section3 {
  text-align: center;
}

.section3 h3 {
  font-weight: bold;
  font-size: 18px;
}
@media (max-width: 768px) {
  .header {
    padding-top: 20%;
  }

  .header h1 {
    font-size: 20px;
  }

  .banner-mobile1 {
    width: 250px;
    height: auto;
  }

  .apple,
  .google {
    width: 100px;
    height: auto;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .section1 h3 {
    font-size: 16px;
  }

  .section1 p {
    font-size: 12px;
  }

  .section2 .cover {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .part2 img {
    width: 150px;
    height: auto;
  }

  .p1,
  .p2 {
    flex-direction: column;
    align-items: center;
  }

  .p1 p,
  .p2 p {
    width: 250px;
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
  }

  .p1 .hr,
  .p2 .hr {
    width: 60%;
    height: 2px;
    margin-top: 30px;
  }

  .section4 h3 {
    font-size: 16px;
    margin-top: 20px;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-top: 20px;
  }

  .footer a {
    font-size: 14px;
    width: auto;
  }

  .footer p {
    font-size: 10px;
    width: auto;
    text-align: center;
    margin-top: 10px;
  }

  .card1,
  .card2,
  .card3 {
    width: 90%;
    padding: 20px 5%;
    text-align: center;
  }

  .card1 img,
  .card2 img,
  .card3 img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: 0;
    margin-bottom: 10px;
  }

  .card1 h3,
  .card2 h3,
  .card3 h3 {
    font-size: 14px;
    margin-top: 10px;
  }

  .card1 p,
  .card2 p,
  .card3 p {
    font-size: 10px;
  }

  .section3 h3 {
    font-size: 16px;
  }
}

/* Responsive styles for JOIN OUR TEAM! section on mobile */
@media (max-width: 768px) {
  .section3 {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(198, 131, 6, 1) 0%,
      rgba(196, 112, 223, 1) 100%
    );
  }

  .section3 h3 {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }

  .card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .card1,
  .card2,
  .card3 {
    width: calc(33.33% - 20px);
    padding: 20px;
    background-color: #80050500;
    border-radius: 10px;
  }

  .card img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto 20px;
  }

  .card h3 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
  }

  .card p {
    color: white;
    font-size: 14px;
    text-align: justify;
  }

  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .card1,
    .card2,
    .card3 {
      width: calc(100% - 20px);
    }
  }
}
