.form {
  display: flex;
  margin-top: 30px;
}

.input-group {
  display: grid;
  margin-left: 25%;
}
.input-group1 {
  display: grid;
  margin-left: 5%;
}

.input-field {
  display: grid;
}

.submit-button {
  text-align: right;
  margin-top: 20px;
  display: grid;
}

.black-button {
  background-color: black;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 10%;
  margin-left: 70%;
}
input {
  width: 300px;
  height: 50px;
}
label {
  color: white;
  font-weight: 700;
}

@media (max-width: 768px) {
  .submit-button {
    text-align: right;
    margin-top: 10px;
    display: grid;
  }

  .black-button {
    background-color: black;
    color: white;
    padding: 10px;
    font-size: 10px;
    width: 15%;
    border: none;
    cursor: pointer;
  }
  .input-group {
    display: grid;
    margin-left: 13%;
  }
  input {
    width: 120px;
    height: 30px;
  }
  label {
    font-size: 13px;
  }
}
