.navContainer {
  width: 100%;
  height: auto;
  background-color: #c80672;
  display: flex;
  padding: 10px;
  position: fixed;
  z-index: 99;
}
.logo {
  width: 200px;
  height: auto;
  margin-left: 50%;
}
#navlinks {
  display: flex;
  margin-left: 35%;
  margin-top: 1%;
}
.home-link,
.about-link,
.contact-link {
  text-decoration: none;
  color: white;
  margin-left: 5%;
  font-size: 13px;
}
.coach-link {
  text-decoration: none;
  color: white;
  margin-left: 5%;
  font-size: 13px;
  width: 300px;
}
.button {
  display: flex;
  background: rgb(198, 131, 6);
  background: linear-gradient(
    180deg,
    rgba(198, 131, 6, 1) 0%,
    rgba(196, 112, 223, 1) 100%
  );
  border-radius: 15px;
  height: 40px;
  margin-top: 10px;
}
h6 {
  color: white;
  width: 100px;
  text-align: center;
  font-size: 11px;
  margin-top: 10px;
}
.vr2 {
  width: 3px;
  height: 40px;
  background-color: white;
}

@media (max-width: 768px) {
  .navContainer {
    padding: 5px;
  }
  .logo {
    width: 80px;
    margin-left: 20%;
    margin-top: 10px;
  }
  #navlinks {
    display: flex;
    margin-left: 10%;
    margin-top: 1%;
  }
  .home-link,
  .about-link,
  .contact-link {
    font-size: 5px;
    margin-top: 10px;
    margin-left: 3%;
  }
  .coach-link {
    font-size: 5px;
    margin-top: 10px;
    margin-left: 3%;
    width: 150px;
  }
  .button {
    display: flex;
    background: rgb(198, 131, 6);
    background: linear-gradient(
      180deg,
      rgba(198, 131, 6, 1) 0%,
      rgba(196, 112, 223, 1) 100%
    );
    border-radius: 15px;
    height: 20px;
    margin-top: 7px;
    margin-left: -30px;
  }
  h6 {
    color: white;
    width: 50px;
    text-align: center;
    font-size: 5px;
    margin-top: 7px;
  }
  .vr2 {
    width: 3px;
    height: 20px;
    background-color: white;
  }
}
